<template>
    <v-app>
        <v-container>

            <v-row style="margin-top:20px">
                <v-col cols="12" md="12">

                    <h1 class="headline mb-2  primary--text" id="title">General Report</h1>
                    <v-card class="pa-5" elevation="5">
                        <template>
                            <div id="app">
                                <GChart type="ColumnChart"
                                        :data="chartData"
                                        :options="chartOptions"
                                        style="height: 350px" />
                            </div>
                        </template>
                    </v-card>
                </v-col>
            </v-row>
            <hr style="margin-top: 60px;" />
            <h1 class="headline mb-2 mt-5 primary--text" id="title">Homestay Profile</h1>
            <h4 class="headline mt-5 text-center">List of Homestay </h4>

            <v-sheet id="slider">
                <v-slide-group v-model="pendingSlider"
                               active-class="success"
                               show-arrows>
                    <v-slide-item v-for="(hotel, index) in hotelData"
                                  :key="index">

                        <v-container>

                            <v-card class="mt-5"
                                    id="slider-card"
                                    max-height="550"
                                    max-width="390"
                                    :elevation="24"
                                    style="background:white">

                                <img :src="hotel.logo" style="width: 100%; height: 200px; object-fit: fill; border: 3px solid black;" />

                                <v-card-title style="font-size:28px;">{{hotel.name}}</v-card-title>

                                <v-card-subtitle class="pb-0 black--text" align="left">
                                    <strong>{{hotel.address}}</strong>
                                </v-card-subtitle>
                                <v-card-text class="text--primary">
                                    <div id="scroller"
                                         style="overflow-y: scroll; max-height: 170px">
                                        Homestays are always much more cheaper than hotels and making your one-time journey a little cost-efficient
                                        as well. You’re going to have your own room but at reasonable rates. The homestay experience is likely a
                                        homely experience where you are directly involved with the living and lifestyle of other people. You get to
                                        have a truly hospitable environment while having a wonderful cuisine selection as per the cultural
                                        practices. Live in a homestay to understand the culture and traditions as part of your traveling as it is
                                        also a learning process. And homestay gives you a truly local experience.
                                    </div>
                                </v-card-text>



                                <v-card-actions class="justify-end" v-if="hotel.divisionName == 'HomeStay'">
                                    <router-link :to="{name: 'HomeStayDetails', params:{homestayID: hotel.branchID}}">HomeStay Details</router-link>
                                </v-card-actions>



                            </v-card>

                        </v-container>
                    </v-slide-item>
                </v-slide-group>
            </v-sheet>

            <v-container>
                <h4 class="headline mt-10 text-center">Customers in Homestay</h4>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-card elevation="5">
                            <template>
                                <div id="app">
                                    <GChart type="ColumnChart"
                                            :data="barsData"
                                            :options="chartOptions"
                                            style="height: 400px;" />
                                </div>
                            </template>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>



            <!--#region test-->


            <hr style="margin-top: 60px;" />
            <h1 class="headline mb-2 mt-5 primary--text" id="title">Homestay Details</h1>
            <v-container>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-card>
                            <v-list style="background-color: #077dfa">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-title class="white--text"><strong>S.No</strong></v-title>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title class="white--text"> <strong>Name</strong> </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="right">
                                        <v-list-item-title class="white--text"><strong>Number of Customers</strong></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                        <v-card style="overflow-y: scroll; height: 350px">
                            <v-list v-for="(hotelCustomer, i) in hotelCustomerData"
                                    :key="i">
                                <!--hotelCustomer.backgroundColor-->

                                <v-list-item :style="{background: hotelCustomer.backgroundColor }">
                                    <v-list-item-avatar style="background-color: white; border-radius: 10px;">
                                        <v-title class="black--text">{{i+1}}</v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="white--text">{{hotelCustomer.branchName}} </v-list-item-title>
                                        <v-list-item-subtitle class="white--text">
                                            {{hotelCustomer.branchAddress}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-avatar align="right">
                                        <v-list-item-title class="white--text">{{hotelCustomer.numberOfPerson}}</v-list-item-title>
                                    </v-list-item-avatar>
                                    <!--<v-list-item-content>
                                        <v-list-item-title class="white--text"></v-list-item-title>
                                        <v-list-item-subtitle class="white--text">
                                            {{hotelCustomer.branchAddress}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-avatar align="center">
                                        <v-list-item-title class="white--text"></v-list-item-title>

                                    </v-list-item-avatar>
                                    <v-list-item-avatar align="right">
                                        <v-list-item-title class="white--text"></v-list-item-title>

                                    </v-list-item-avatar>-->
                                </v-list-item>
                                <v-divider inset></v-divider>
                            </v-list>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card>
                            <v-list style="background-color: #077dfa">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-title class="white--text"><strong>S.No</strong></v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="white--text"><strong>  Name </strong></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="right">
                                        <v-list-item-title class="white--text"><strong> Number of Employees</strong></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                        <v-card style="overflow-y: scroll; height: 350px">
                            <v-list v-for="(hotelEmployee, j) in hotelEmployeeData"
                                    :key="j">
                                <v-list-item :style="{background: hotelEmployee.backgroundColor }">

                                    <v-list-item-avatar style="background-color: white; border-radius: 10px;">
                                        <v-title class="black--text">{{j+1}}</v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="white--text">{{hotelEmployee.branchName}} </v-list-item-title>
                                        <v-list-item-subtitle class="white--text">
                                            {{hotelEmployee.branchAddress}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-avatar align="right">
                                        <v-list-item-title class="white--text">{{hotelEmployee.totalEmployee}}</v-list-item-title>
                                    </v-list-item-avatar>

                                    <!--<v-list-item-avatar style="background-color: white; border-radius: 10px;">

                                        <v-title class="black--text">{{j+1}}</v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="white--text"> {{hotelEmployee.branchName}} </v-list-item-title>
                                        <v-list-item-subtitle class="white--text">
                                            {{hotelEmployee.branchAddress}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-avatar align="right">
                                        <v-list-item-title class="white--text">{{hotelEmployee.divisionName}}</v-list-item-title>
                                    </v-list-item-avatar>
                                    <v-list-item-avatar align="right">
                                        <v-list-item-title class="white--text">{{hotelEmployee.totalEmployee}}</v-list-item-title>
                                    </v-list-item-avatar>-->
                                </v-list-item>
                                <v-divider inset></v-divider>
                            </v-list>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4" elevation="10">
                        <v-card>
                            <v-list style="background-color: #077dfa">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-title class="white--text"><strong>S.No</strong></v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="white--text"><strong>  Name </strong></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="right">
                                        <v-list-item-title class="white--text"><strong> Number of Houses</strong></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                        <v-card style="overflow-y: scroll; height: 350px">
                            <v-list v-for="(hotelRoom, j) in hotelRoomData"
                                    :key="j">

                                <v-list-item :style="{background: hotelRoom.backgroundColor }">

                                    <v-list-item-avatar style="background-color: white; border-radius: 10px;">
                                        <v-title class="black--text">{{j+1}}</v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="white--text">{{hotelRoom.branchName}} </v-list-item-title>
                                        <v-list-item-subtitle class="white--text">
                                            {{hotelRoom.branchAddress}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-avatar align="right">
                                        <v-list-item-title class="white--text">{{hotelRoom.totalRoom}}</v-list-item-title>
                                    </v-list-item-avatar>


                                    <!--<v-list-item :style="{background: hotelRoom.backgroundColor }">
                                        <v-list-item-avatar style="background-color: white; border-radius: 10px;">
                                            <v-title>{{j+1}}</v-title>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title class="white--text"> {{hotelRoom.branchName}} </v-list-item-title>
                                            <v-list-item-subtitle class="white--text">
                                                {{hotelRoom.branchAddress}}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-avatar align="right">
                                            <v-list-item-title class="white--text">{{hotelRoom.divisionName}}</v-list-item-title>
                                        </v-list-item-avatar>
                                        <v-list-item-avatar align="right">
                                            <v-list-item-title class="white--text">{{hotelRoom.totalRoom}}</v-list-item-title>
                                        </v-list-item-avatar>-->
                                </v-list-item>
                                <v-divider></v-divider>
                            </v-list>
                        </v-card>
                    </v-col>

                </v-row>
            </v-container>


            <!--#endRegion-->



        </v-container>
    </v-app>
</template>

<script>
    import { mdiClock, mdiStore, mdiNaturePeople, mdiHeadset } from "@mdi/js";
    //import publicAxios from '../../simple-axios'
    import publicAxios from "axios";
    /*import router from "../../../routes/router";*/
    export default {
        data: () => ({
            pendingSlider: null,
            IndividulData: [],
            hotelData: [],
            hotelCustomerData: [],
            hotelEmployeeData: [],
            hotelRoomData: [],
            chartData: [],
            chartOptions: {
                chart: {
                    title: "Company Performance",
                    subtitle: "Sales, Expenses, and Profit: 2014-2020",
                },
            },

            barsData: [],
            RoomData: [
                ["Year", " Total Room", "Room Booked"],
                ["2011", 2000, 1000],
                ["2012", 2170, 1500],
                ["2013", 2060, 1800],
                ["2014", 3030, 2100],
                ["2015", 4025, 3200],
                ["2016", 4040, 3400],
                ["2017", 5000, 4000],
                ["2018", 5800, 4100],
                ["2019", 6800, 3000],
                ["2020", 7000, 600],
            ],
            slides: [
                {
                    img: '../../', hotelName: 'The Soaltee Kathmandu', location: 'Tahachal Marg, Kathmandu 44600, Nepal', description: "Soaltee Hotel Limited (SHL), a pioneer in Nepal's hospitality sector, owns the Soaltee Kathmandu, a 12-acre five- star deluxe hotel.A landmark in the city of Kathmandu with a rich heritage, the hotel is the proud recipient of several prestigious awards and accolades over the years.Soaltee Hotel Limited is renowned for its rich  history as a pioneer in Nepal�s tourism industry with its  contribution of luxurious properties and legendary hospitality across the region."
                },
                {
                    img: '../../', hotelName: 'The Yak & Yeti', location: 'Tahachal Marg, Kathmandu 44600, Nepal', description: "Soaltee Hotel Limited (SHL), a pioneer in Nepal's hospitality sector, owns the Soaltee Kathmandu, a 12-acre five- star deluxe hotel.A landmark in the city of Kathmandu with a rich heritage, the hotel is the proud recipient of several prestigious awards and accolades over the years.Soaltee Hotel Limited is renowned for its rich  history as a pioneer in Nepal�s tourism industry with its  contribution of luxurious properties and legendary hospitality across the region.",
                },
                {
                    img: '../../', hotelName: 'The Soaltee Kathmandu', location: 'Tahachal Marg, Kathmandu 44600, Nepal', description: "Soaltee Hotel Limited (SHL), a pioneer in Nepal's hospitality sector, owns the Soaltee Kathmandu, a 12-acre five- star deluxe hotel.A landmark in the city of Kathmandu with a rich heritage, the hotel is the proud recipient of several prestigious awards and accolades over the years.Soaltee Hotel Limited is renowned for its rich  history as a pioneer in Nepal�s tourism industry with its  contribution of luxurious properties and legendary hospitality across the region.",
                }








            ],
            series: [400, 450, 350, 310, 280, 330, 520, 120, 130, 250],
            series1: [200, 250, 210, 190, 230, 240, 120, 220, 160, 150],
            chartsOptions: {
                chart: {
                    width: 800,
                    type: "donut",
                },
                labels: [
                    "Hyatt Regency",
                    "Yak & Yeti",
                    "Godavari Village Resort",
                    "Hotel Everest View",
                    "Begnas Lake Resort",
                    "Hotel Golden Plaza",
                    "The Dwarika's Hotel",
                    "Kathmandu Marriott Hotel",
                    "Kathmandu Marriott Hotel",
                    "Pokhara Grande",
                ],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 500,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
            width: 2,
            radius: 10,
            padding: 8,
            lineCap: "round",
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            fill: false,
            type: "trend",
            autoLineWidth: false,
            svgClock: mdiClock,
            svgStore: mdiStore,
            svgCustomer: mdiNaturePeople,
            svgHeading: mdiHeadset,
        }),
        methods: {
            async getHotelData() {
                const { data } = await publicAxios.post('HotelDetails/GetAllHomestayDetailsList')
                this.hotelData = data
                console.log('this.hotelData', this.hotelData)
            },
            async getCustomerData() {
                const { data } = await publicAxios.get('HotelDetails/GetHomestayCustomerCountsAsync')
                this.hotelCustomerData = data
                console.log('this.hotelCustomerData', this.hotelCustomerData)
            },
            async getEmployeeData() {

                const { data } = await publicAxios.get('HotelDetails/GetHomestayEmployeesCountsAsync')
                this.hotelEmployeeData = data

                console.log('this.hotelEmployeeData', this.hotelEmployeeData)
            },
            async getRoomData() {
                const { data } = await publicAxios.get('HotelDetails/GetHomestayRoomcountAsync')
                this.hotelRoomData = data
                console.log('this.hotelRoomData', this.hotelRoomData)
            },
            async getHotelChartData() {
                const { data } = await publicAxios.get('HotelDetails/GetHomestayLineChartAsync?Type=HomeStay')
                let formatedData = this.getChartData(data)
                this.chartData = formatedData
            },

            async getHotelCustomerCountryWise() {
                const { data } = await publicAxios.get('HotelDetails/GetHomestayCustomerCountrywisecountAsync?Type=HomeStay')

                let formatedData = this.getChartData(data)
                this.barsData = formatedData
                console.log('this.hotelCustomerCountryWise', this.barsData)
            },

            getChartData(data) {
                console.log(data)
                let arr = []
                arr[0] = Object.keys(data[0])
                for (let i = 1; i < data.length + 1; i++) {
                    arr[i] = Object.values(data[i - 1])
                }
                return arr;
            }

        },
        components: {

        },
        computed: {
            theme() {
                return (this.$vuetify.theme.dark) ? 'dark' : 'light'
            }
        },
        created() {
            this.getHotelData()
            this.getCustomerData()
            this.getEmployeeData()
            this.getHotelChartData()
            this.getRoomData()
            this.getHotelCustomerCountryWise()
        }

    }
</script>
<style scoped>
    ::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

    .v-progress-circular {
        margin: 1rem;
    }

    @media screen and (max-width:1903px) {
        #slider-card {
            width: 328px;
        }
    }

    @media screen and (max-width:1263px) {
        #slider-card {
            width: 362px;
        }

        .col-12 {
            -webkit-box-flex: 0;
            flex: 0 0 100%;
            max-width: 100%;
        }

        #title {
            margin-left: 20px;
        }
    }

    @media screen and (max-width:980px) {
        #slider-card {
            width: 310px;
        }

        #app {
            margin-left: 10px;
        }
    }

    @media screen and (max-width:910px) {
        #slider-card {
            width: 310px;
        }
    }

    @media screen and (max-width:876px) {
        #slider-card {
            width: 280px;
        }
    }

    @media screen and (max-width:815px) {
        #slider-card {
            width: 250px;
        }
    }

    @media screen and (max-width:756px) {
        #slider-card {
            width: 550px;
        }
    }

    @media screen and (max-width:628px) {
        #slider-card {
            width: 350px;
        }
    }

    @media screen and (max-width:589px) {
        #slider-card {
            width: 250px;
        }
    }


    @media screen and (max-width:487px) {
        #slider-card {
            width: 220px;
        }
    }
</style>