<template>
    <div>
        <div v-if="isDivision">
            <HomeStayDivisionDashboard></HomeStayDivisionDashboard>
        </div>
        <div v-else-if="isProvinceModule">
            <HomeStayDivisionDashboard></HomeStayDivisionDashboard>
        </div>
        <div v-else-if="isAssociation">
            <AssociationDivisionDashboard></AssociationDivisionDashboard>
        </div>
        <div v-else-if="isParentDivision">
            <ParentDivisionDashboard></ParentDivisionDashboard>
        </div>
        <div v-else>
            <template>
                <v-container>
                    <h4 v-if="!isNepaliLanguage"
                        class="headline mt-5 text-center primary--text">
                        <strong>{{ hotelBranchId.name }}</strong>
                    </h4>
                    <h4 v-else class="headline mt-5 text-center primary--text">
                        <strong>{{ hotelBranchId.nameNepali }}</strong>
                    </h4>
                    <div id="MainContainer">
                        <v-row>
                            <v-col cols="12" sm="10">
                                <v-card class="mt-5"
                                        id="slider-card"
                                        max-height="550"
                                        width="100%"
                                        :elevation="24"
                                        style="background:white">
                                    <img :src="hotelBranchId.logo" id="hotellogo" />
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="2">
                                <v-row class="mt-10">
                                    <v-card id="box">
                                        <v-col cols="12" sm="12" class="mt-2">
                                            <v-card id="sideMainBox"
                                                    class="box"
                                                    @click="$router.push('/branch')"
                                                    color="red"
                                                    elevation="5">
                                                <p id="titleForBox">Profile</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-avatar>
                                                                <v-icon class="boxIcon" color="white">people</v-icon>
                                                            </v-list-avatar>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{ $t("profile") }}
                                                                    </p>
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" sm="12" class="mt-2">
                                            <v-card id="sideMainBox"
                                                    class="box"
                                                    @click="$router.push('/HomeStay')"
                                                    color="#006400"
                                                    elevation="5">
                                                <p id="titleForBox">Customer</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-avatar>
                                                                <v-icon class="boxIcon" color="white">people</v-icon>
                                                            </v-list-avatar>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{ $t("customer") }}
                                                                    </p>
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>

                                        <v-col cols="12" sm="12" class="mt-2">
                                            <v-card id="sideMainBox"
                                                    class="box"
                                                    @click="$router.push('/homestayReport')"
                                                    color="#088400"
                                                    elevation="5">
                                                <p id="titleForBox">Report</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-avatar>
                                                                <v-icon class="boxIcon" color="white">people</v-icon>
                                                            </v-list-avatar>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{ $t("report") }}
                                                                    </p>
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>

                                        <v-col cols="12" sm="12" class="mt-2">
                                            <v-card id="sideMainBox"
                                                    @click="$router.push('/homeStayEmployeeList')"
                                                    color="#FFA500"
                                                    elevation="5">
                                                <p id="titleForBox">Employee</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-item-avatar>
                                                                <v-icon class="boxIcon" color="white">directions_run</v-icon>
                                                            </v-list-item-avatar>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{ $t("employee") }}
                                                                    </p>
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" sm="12" class="mt-2">
                                            <v-card id="sideMainBox"
                                                    @click="$router.push('/HomeStay/room')"
                                                    color="#077dfa"
                                                    elevation="5">
                                                <p id="titleForBox">Room</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-item-avatar>
                                                                <v-icon class="boxIcon" color="white">room_service</v-icon>
                                                            </v-list-item-avatar>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{ $t("settings") }}
                                                                    </p>
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-card>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                    <v-container>
                        <h4 v-if="!isNepaliLanguage" class="headline mt-10 text-center">
                            Houses in {{ hotelBranchId.name }}
                        </h4>
                        <h4 v-else class="headline mt-10 text-center">
                            {{ hotelBranchId.nameNepali }} मा घरहरू
                        </h4>
                        <v-row>
                            <v-col cols="12" md="12" class="mt-5">
                                <v-card elevation="5">
                                    <template>
                                        <div id="app">
                                            <v-carousel v-model="model" height="auto" hide-delimiters show-arrows-on-hover>
                                                <v-carousel-item v-for="(images,index) in homestayImage" :key="'imagec-'+index">
                                                    <v-row>
                                                        <v-col class="p-3" cols="3" v-for="(image,index) in images" :key="'imagec-'+index">

                                                            <v-img :src="image.imageurl"
                                                                   alt="image"
                                                                   height="230" />
                                                        </v-col>
                                                    </v-row>

                                                </v-carousel-item>
                                            </v-carousel>

                                        </div>
                                    </template>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                    <hr style="margin-top: 60px;" />
                    <!--<v-row>
                    <v-col cols="12" md="6">
                        <h1 class="headline mb-2 mt-5 primary--text" style="margin-left:20px">{{hotelBranchId.name}} Customers</h1>
                    </v-col>
                    <v-col cols="12" md="6">
                        <h1 class="headline mb-2 mt-5 primary--text">{{hotelBranchId.name}} Employees</h1>
                    </v-col>
                </v-row>-->
                    <v-container>
                        <v-row>
                            <!--<v-col cols="12" md="6">
                            <h1 class="headline mb-2 mt-5 primary--text">{{hotelBranchId.name}} Customers</h1>
                            <v-card>
                                <v-list style="background-color: #077dfa">
                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-title id="table" class="white--text"><strong>S.No</strong></v-title>
                                        </v-list-item-avatar>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table" class="white--text"><strong> Customer Name</strong> </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table" class=" white--text"><strong>Room Number</strong></v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table" class=" white--text"><strong>Check In Date</strong></v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="right">
                                            <v-list-item-title id="table" class="white--text"><strong>Check Out Date</strong></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                            <v-card style="overflow-y: scroll; height: 350px;">

                                <v-list v-for="(hotelCheckInOut, i) in hotelCustomerCheckInOut"
                                        :key="i">
                                    <v-list-item :style="{background: hotelCheckInOut.backgroundColor }">
                                        <v-list-item-avatar>
                                            <v-title id="table" class="black--text">{{i+1}}</v-title>
                                        </v-list-item-avatar>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table" class="black--text">{{hotelCustomer.fullName}} </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table" class="mr-18 black--text"> {{hotelCheckInOut.roomNumber}}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table" class="black--text"> {{hotelCheckInOut.checkInDate}}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="right">
                                            <v-list-item-title id="table" class="black--text"> {{hotelCheckInOut.checkOutDate}}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider inset></v-divider>
                                </v-list>
                            </v-card>
                        </v-col>-->

                            <v-col cols="12" md="6">
                                <h1 v-if="!isNepaliLanguage"
                                    class="headline mb-2 mt-5 primary--text">
                                    {{ hotelBranchId.name }} Customers
                                </h1>
                                <h1 v-else class="headline mb-2 mt-5 primary--text">
                                    {{ hotelBranchId.nameNepali }} का ग्राहकहरू
                                </h1>
                                <v-card>
                                    <v-list style="background-color: #077dfa">
                                        <v-list-item>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="white--text">
                                                    <strong>{{ $t("persons") }}</strong>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class=" white--text"><strong>{{ $t("type") }}</strong></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class=" white--text">
                                                    <strong>
                                                        {{
                            $t("check_in")
                                                        }}
                                                    </strong>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class=" white--text">
                                                    <strong>
                                                        {{
                            $t("check_out")
                                                        }}
                                                    </strong>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class=" white--text"><strong>{{ $t("male") }}</strong></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="right">
                                                <v-list-item-title id="table" class="white--text">
                                                    <strong>
                                                        {{
                            $t("female")
                                                        }}
                                                    </strong>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="right">
                                                <v-list-item-title id="table" class="white--text">
                                                    <strong>
                                                        {{
                            $t("age_group")
                                                        }}
                                                    </strong>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                                <v-card style="overflow-y: scroll; height: 350px;">
                                    <v-list v-for="(hCustomerDetails, i) in branchCustomerDetails"
                                            :key="i">
                                        <v-list-item :style="{ background: hCustomerDetails.backgroundColor }">
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="black--text">
                                                    {{ hCustomerDetails.numberOfPerson }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="mr-18 black--text">
                                                    {{ hCustomerDetails.nameEn }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="mr-18 black--text">
                                                    {{ hCustomerDetails.checkInDate }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="mr-18 black--text">
                                                    {{ hCustomerDetails.checkOutDate }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="black--text">
                                                    {{ hCustomerDetails.totalMale }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="right">
                                                <v-list-item-title id="table" class="black--text">
                                                    {{ hCustomerDetails.totalFemale }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="right">
                                                <v-list-item-title id="table" class="black--text">
                                                    {{ hCustomerDetails.ageGroup }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider inset></v-divider>
                                    </v-list>
                                </v-card>
                            </v-col>

                            <v-col cols="12" md="6">
                                <h1 v-if="!isNepaliLanguage"
                                    class="headline mb-2 mt-5 primary--text">
                                    {{ hotelBranchId.name }} Employees
                                </h1>
                                <h1 v-else class="headline mb-2 mt-5 primary--text">
                                    {{ hotelBranchId.nameNepali }} का कर्मचारी
                                </h1>
                                <v-card>
                                    <v-list style="background-color: #077dfa">
                                        <v-list-item>
                                            <v-list-item-avatar>
                                                <v-title id="table" class="white--text"><strong>{{ $t("s_no") }}</strong></v-title>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title id="table"
                                                                   align="center"
                                                                   class="white--text">
                                                    <strong>{{ $t("employee_name") }}</strong>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="mr-18 white--text"><strong>{{ $t("email") }}</strong></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="white--text">
                                                    <strong>
                                                        {{
                            $t("position")
                                                        }}
                                                    </strong>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="right">
                                                <v-list-item-title id="table" class="white--text">
                                                    <strong>
                                                        {{
                            $t("contact_no")
                                                        }}
                                                    </strong>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                                <v-card style="overflow-y: scroll; height: 350px">
                                    <v-card style="height: 350px;">
                                        <v-list v-for="(hotelEmployee, i) in hotelEmployeeDetails"
                                                :key="i">
                                            <v-list-item :style="{ background: hotelEmployee.backgroundColor }">
                                                <v-list-item-avatar>
                                                    <v-title id="table" class="black--text">
                                                        {{
                            i + 1
                                                        }}
                                                    </v-title>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title id="table"
                                                                       align="center"
                                                                       class="black--text">{{ hotelEmployee.empName }}</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-content align="center">
                                                    <v-list-item-title id="table"
                                                                       class="mr-18 black--text">{{ hotelEmployee.empEmail }}</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-content align="center">
                                                    <v-list-item-title id="table" class="black--text">
                                                        {{ hotelEmployee.empType }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-content align="right">
                                                    <v-list-item-title id="table" class="black--text">
                                                        {{
                            hotelEmployee.empPhone
                                                        }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider></v-divider>
                                        </v-list>
                                    </v-card>
                                </v-card>
                                <!--<button @click="$router.push('/hotelEmployee')" class=" white--text primary align-end" dark style="padding:5px; border-radius:5px; margin-top:10px;">For More Details</button>-->
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-container>
                        <h4 v-if="!isNepaliLanguage"
                            class="headline md-10 text-center mt-5">
                            Monthly Report of {{ hotelBranchId.name }}
                        </h4>
                        <h4 v-else class="headline md-10 text-center mt-5">
                            {{ hotelBranchId.nameNepali }} को मासिक रिपोर्ट
                        </h4>
                        <v-row>
                            <v-col cols="12" md="12" class="mt-5">
                                <template>
                                    <v-card elevation="5">
                                        <div id="app">


                                            <vue-good-table class="narrow-table"
                                                            mode="remote"
                                                            @on-page-change="onPageChange"
                                                            @on-sort-change="onSortChange"
                                                            @on-column-filter="onColumnFilter"
                                                            @on-per-page-change="onPerPageChange"
                                                            :totalRows="totalRecords"
                                                            :isLoading.sync="isLoading"
                                                            :pagination-options="{ enabled: true, mode: 'records' }"
                                                            :rows="hotelRoomReport"
                                                            :columns="columns">
                                                <template slot="table-column" slot-scope="props">
                                                    <div class="cell-wrapper">
                                                        <span>
                                                            {{ $t(props.column.label) }}
                                                        </span>
                                                    </div>
                                                </template>
                                                <template slot="table-row" slot-scope="props">
                                                    <tbody>
                                                        <tr :class="
                props.row.isCustomerCheckOut
                  ? 'green accent-2'
                  : 'purple lighten-5'
              "
                                                            v-for="rdata in rows"
                                                            :key="rdata.id">
                                                            <td>{{ rdata.amount }}</td>
                                                            <td>{{ rdata.hotelCustomerID }}</td>
                                                        </tr>
                                                    </tbody>
                                                </template>

                                                <template :class="
            props.row.isCustomerCheckOut ? 'green accent-2' : 'purple lighten-5'
          "
                                                          slot="table-row"
                                                          slot-scope="props">
                                                    <span v-if="props.column.field == 'actions'">
                                                        <v-icon small class="mr-2" @click="editItem(props.row)">edit</v-icon>
                                                        <v-icon small @click="deleteItem(props.row)">delete</v-icon>
                                                    </span>

                                                    <span v-else-if="props.column.field == 'roomReserved'">
                                                        <span v-if="props.row.roomReserved == 0">
                                                            Yes
                                                        </span>
                                                        <span v-else>
                                                            No
                                                        </span>
                                                    </span>

                                                    <span v-else-if="props.column.field == 'checkInDate'">
                                                        {{ props.row.checkInDate | moment("DD-MMM-YYYY") }}
                                                    </span>
                                                    <span v-else-if="props.column.field == 'checkOutDate'">
                                                        {{
            props.row.checkOutDate | moment("DD-MMM-YYYY")
                                                        }}
                                                    </span>
                                                    <span v-else-if="props.column.field == 'isCustomerCheckOut'">
                                                        <v-icon v-if="props.row.isCustomerCheckOut" small class="mr-2">done</v-icon>
                                                        <v-icon v-else>close</v-icon>
                                                    </span>
                                                </template>
                                            </vue-good-table>



                                        </div>
                                    </v-card>
                                </template>
                            </v-col>
                        </v-row>
                    </v-container>
                    <!--<v-row class="my-5">
                    <hr style="margin-top: 60px; width:100% " />
                    <v-col cols="12" md="12">
                        <h1 class="headline mb-2 primary--text">{{hotelBranchId.name}} Room</h1>
                        <v-card elevation="5">
                            <template>
                                <div id="app">
                                    <GChart type="ColumnChart"
                                            :data="RoomData"
                                            :options="chartOptions"
                                            style="height: 350px" />
                                </div>
                            </template>
                        </v-card>
                    </v-col>
                </v-row>-->
                </v-container>
            </template>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
import HomeStayDivisionDashboard from "../../dashboard/HomeStayDivisionDashboard.vue";
    import ParentDivisionDashboard from "../../home_stay/Dashboard/HomestayAssociationDashboard.vue";
    import AssociationDivisionDashboard from "../../hotel/Dashboard/AssociationDashboard.vue";

import { mdiClock, mdiStore, mdiNaturePeople, mdiHead } from "@mdi/js";
/*    import VueApexCharts from "vue-apexcharts";*/
import { mapGetters } from "vuex";
import publicAxios from "../../../simple-axios";
// import Vue from 'vue/types/umd';
// Vue.use(VueApexCharts)
export default {
  props: ["btn-text"],
  computed: {
    ...mapGetters(["getSystemUserData"]),
    isDivision() {
      return this.getSystemUserData.role === "Division";
    },
    isProvinceModule() {
      return this.getSystemUserData.role === "ProvinceModule";
    },
      isAssociation() {
      return this.getSystemUserData.role === "Association";
      },
      isParentDivision() {
          return this.getSystemUserData.role === "ParentDivision";
      },
    isBranchData() {
      return this.getSystemUserData.BranchID;
    },
    isNepaliLanguage() {
      return this.$i18n.locale === "ne";
    },
  },
  data: () => ({
    //hotelCustomerCheckInOut: [],
    branchCustomerDetails: [],
    hotelEmployeeDetails: [],
    hotelBranchId: [],
    chartData: [],
    chartOptions: {
      chart: {
        title: "Company Performance",
        subtitle: "Sales, Expenses, and Profit: 2014-2020",
      },
      },
      homestayImage:[],
    barData: [],
    barsData: [],
    customerData: [],
    RoomData: [],
    width: 2,
    radius: 10,
    padding: 8,
    lineCap: "round",
    value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
    fill: false,
    type: "trend",
    autoLineWidth: false,
    svgClock: mdiClock,
    svgStore: mdiStore,
    svgCustomer: mdiNaturePeople,
      svgHeading: mdiHead,
      serverParams: {
          search: "",
          columnFilters: {},
          sort: [
              {
                  field: "countIndex",
                  type: "desc",
              },
          ],
          page: 1,
          perPage: 10,
          houseNumber: "",
          houseOwner: "",
      },
      hotelRoomReport:[],
      columns: [
          //{
          //    label: "S No.",
          //    field: "countIndex",
          //    width: '70px'
          //},

          { label: "s_no", field: "index" },
          { label: "house_name", field: "houseName" },
          { label: "house_no", field: "houseNumber" },
          { label: "house_owner_name", field: "houseOwner" },
          { label: "customers", field: "customerCount" },

          //{ label: "TotalPerson", width: '280px', field: "fullName" },
          //{ label: "TotalRomms", width: '170px', field: "passportNumber" },
          //{ label: "Amount", width: '150px', field: "checkInDate" },
          //{ label: "RoomCategoryId", width: '150px', field: "checkOutDate" }
          //{ label: "Checkout Status", width: '90px', field: "roomReserved" },
          //{ label: "Actions", width: '70px', field: "actions" }
      ],
      rows: [],
  }),
  components: {
    /*apexchart: VueApexCharts,*/
      HomeStayDivisionDashboard,
      AssociationDivisionDashboard,
      ParentDivisionDashboard,

    /*VueApexCharts,*/
  },
        methods: {

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field,
                        },
                    ],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

    async getHotelCustomerCountryWise() {
      const { data } = await publicAxios.get(
        "HotelDetails/GetBranchHotelCustomerCountrywisecountAsync/0"
      );
      let formatedData = this.getChartData(data);
      this.barsData = formatedData;
      console.log("this.HotelCustomerCountrywisecountAsync", this.barsData);
    },
    //async getBranchCustomerCheckInOut() {
    //    const { data } = await publicAxios.get("HotelDetails/GetBranchHotelCustomerCheckInOut/0")
    //    this.hotelCustomerCheckInOut = data
    //    console.log('this.hotelCustomerCheckInOut', this.hotelCustomerCheckInOut)
    //},

    async getBranchCustomerDetails() {
      const { data } = await publicAxios.get(
        "HotelDetails/GetBranchHomestayCustomer/0"
      );
      this.branchCustomerDetails = data;
      console.log("this.branchCustomerDetails", this.branchCustomerDetails);
    },

    async getHotelCustomerCountBar() {
      const { data } = await publicAxios.get(
        "HotelDetails/GetHotelCustomerMonthWise/0"
      );
      let formatedData = this.getChartData(data);
      this.customerData = formatedData;
      console.log("this.HotelBranchCustomerCountBar", this.customerData);
    },
    async getHotelRoomTotalBookedCount() {
      const { data } = await publicAxios.post(
        "HotelDetails/GetHotelRoomStatusMonthWise/0"
      );
      let formatedData = this.getChartData(data);
      this.RoomData = formatedData;
      console.log("this.HotelRoomTotalBookedCount", this.RoomData);
    },
    async getBranchEmployeeDetails() {
      const { data } = await publicAxios.post(
        "HotelDetails/GetHotelEmployeeDetails/0"
      );
      this.hotelEmployeeDetails = data;
      console.log("this.hotelEmployeeDetails", this.hotelEmployeeDetails);
    },
    async getHotelBranchId() {
      const { data } = await publicAxios.post(
        "HotelDetails/GetIndividualHotelDetails/0"
      );
      this.hotelBranchId = data;
      console.log("this.hotelBranchData", this.hotelBranchId);
      },
      async getHomestayRoomReport() {
          this.loading = true;
          console.log(" I am ok ")
            
              axios.post("HotelDetails/RoomReportAsync/0", {})
                  .then((response) => {
                      if (response.data) {
                          let indexData = response.data.map((response, index) => ({
                              s_no: index + 1,
                              ...response,
                          }));
                          console.log("this >>> ", indexData);
                          let head = Object.keys(indexData[0]);
                          this.columns = head.map((res) => ({
                              label: res,
                              field: res,
                              sortable: false,
                          }));
                          this.hotelRoomReport = indexData;
                          this.totalRecords = response.data.totalCount;
                      }

                      this.loading = false;
                  })
                  .catch((err) => {
                      console.log(err);
                  });
              console.log("load", this.apiData);
          },

      async getHomestayImages() {

          const { data } = await publicAxios.post("HotelDetails/GetHomestayImage/0")
          console.log(data)
          this.homestayImage = this.chunkData(data,4)
          console.log('this.homestayImage', this.homestayImage)
      },
    getChartData(data) {
      console.log(data);
      let arr = [];
      arr[0] = Object.keys(data[0]);
      for (let i = 1; i < data.length + 1; i++) {
        arr[i] = Object.values(data[i - 1]);
      }
      return arr;
            },

  chunkData (items, size) {  
  const chunks = []
  items = [].concat(...items)

  while (items.length) {
    chunks.push(
      items.splice(0, size)
    )
  }

  return chunks
}
  },
        created() {
            this.getHomestayImages();
            this.getHomestayRoomReport();
            this.getHotelCustomerCountryWise();
            //this.getBranchCustomerCheckInOut()
            this.getBranchCustomerDetails();
            this.getBranchEmployeeDetails();
            this.getHotelCustomerCountBar();
            this.getHotelRoomTotalBookedCount();
            this.getHotelBranchId();
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.menu-icon {
  color: #fff;
}
.pie-chart {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 2px solid #fefefe;
}

::-webkit-scrollbar {
  width: 3px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-progress-circular {
  margin: 1rem;
}

@media screen and (min-width: 1903px) {
  #sideBox {
    font-size: 16px;
    color: white;
  }

  #titleForBox {
    display: none;
    text-align: center;
  }

  .boxIcon {
    font-size: 36px;
  }

  #hotellogo {
    width: 100%;
    height: 550px;
    object-fit: fill;
  }

  #box {
    height: 615px;
  }

  #MainContainer {
    margin-left: 90px;
  }
}

@media screen and (max-width: 1903px) {
  #sideBox {
    font-size: 16px;
    color: white;
  }

  #titleForBox {
    display: none;
    text-align: center;
  }

  #table {
    font-size: 12px !important;
  }

  .boxIcon {
    font-size: 26px;
  }

  #hotellogo {
    width: 890px;
    height: 550px;
    object-fit: fill;
  }

  #box {
    height: 615px;
  }

  #MainContainer {
    margin-left: 90px;
  }
}

@media screen and (max-width: 1263px) {
  #sideBox {
    font-size: 12px;
    color: white;
  }

  .boxIcon {
    font-size: 20px;
  }

  #box {
    height: 525px;
  }

  #table {
    font-size: 10px !important;
  }

  #sideMainBox {
    height: 80px;
  }

  #table {
    font-size: 12px !important;
  }

  #hotellogo {
    width: 650px;
    height: 550px;
    object-fit: fill;
  }

  #MainContainer {
    margin-left: 90px;
  }
}

@media screen and (max-width: 979px) {
  #sideBox {
    font-size: 10px;
    color: white;
  }

  #sideMainBox {
    height: 70px;
  }

  #box {
    height: 485px;
  }

  #table {
    font-size: 10px !important;
  }

  #hotellogo {
    width: 100%;
    height: 550px;
    object-fit: fill;
  }

  #MainContainer {
    margin-left: 70px;
  }
}

@media screen and (max-width: 860px) {
  #sideBox {
    display: none;
  }

  #table {
    font-size: 10px !important;
  }

  #sideMainBox {
    height: 70px;
    width: 100%;
    padding: 25px;
  }

  .boxIcon {
    /*  font-size: 26px;
            margin-bottom:15px;*/
    display: none;
  }

  #titleForBox {
    color: white;
    display: block;
    text-align: center;
  }

  #box {
    width: 100%;
    height: 535px;
  }

  #hotellogo {
    width: 100%;
    height: 550px;
    object-fit: fill;
  }

  .col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  #MainContainer {
    margin-left: 40px;
  }
}

@media screen and (max-width: 750px) {
  #sideBox {
    display: none;
  }

  #table {
    font-size: 10px !important;
  }

  #sideMainBox {
    height: 70px;
    width: 100%;
  }

  #box {
    width: 100%;
    height: 535px;
  }

  #hotellogo {
    width: 100%;
    height: 450px;
    object-fit: fill;
  }

  .col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  #MainContainer {
    margin-left: 40px;
  }
}

@media screen and (max-width: 640px) {
  #sideBox {
    display: none;
  }

  #table {
    font-size: 10px !important;
  }

  #hotellogo {
    width: 100%;
    height: 350px;
    object-fit: fill;
  }
}

@media screen and (max-width: 599px) {
  #sideBox {
    display: none;
  }

  #table {
    font-size: 10px !important;
  }

  #hotellogo {
    width: 100%;
    height: 250px;
    object-fit: fill;
  }
}
</style>
